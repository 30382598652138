import axios from 'axios';
import { CHAT_DOMAIN } from '../constants';
import { generateUUID } from '../utils/utils';

// eslint-disable-next-line no-restricted-globals
const baseURL = location.hostname === 'localhost' ? '/api/v4' : CHAT_DOMAIN;

export const getMyAuthToken = () => {
  return axios.get('/api/mattermost/user_token');
};

export const getChats = (
  // archived?: boolean,
  group?: string,
  type?: string
) => {
  return axios.get(`/api/mattermost/user_channels`, {
    params: {
      group,
      type,
      // archived: archived || undefined,
    },
  });
};

export const getChat = (id: any) => {
  if (id.companyId) {
    return axios.post(`/api/mattermost/group_channel`, undefined, {
      params: { company_id: id.companyId },
    });
  }
  if (id.userId) {
    return axios.post(`/api/mattermost/group_channel`, undefined, {
      params: { user_id: id.userId },
    });
  }
  return axios.post(`/api/mattermost/group_channel`);
};

export const getPostsForChannel = (channelId: string, page: number, before?: string) => {
  return axios.get(`/channels/${channelId}/posts`, {
    baseURL,
    params: { page, per_page: 1000, before },
  });
};

export const createPost = (
  channelId: string,
  message: string,
  props?: {},
  fileIds?: string[]
) => {
  const data = {
    channel_id: channelId,
    message,
    props: props || {},
    file_ids: fileIds,
  };
  return axios.post('/posts', data, { baseURL });
};

export const getMe = () => {
  return axios.get('/users/me', { baseURL });
};

export const uploadFile = (channelId: string, file: File, name: string) => {
  const formData = new FormData();
  formData.append('channel_id', channelId);
  formData.append('client_ids', generateUUID());
  formData.append('files', file);
  const options = {
    headers: { 'Content-Type': 'multipart/form-data' },
    baseURL,
    params: {
      channel_id: channelId,
      fileName: name,
    },
  };
  return axios.post('/files', formData, options);
};

export const getUploadedFile = (id: string, isPreview?: boolean) => {
  return axios.get(`/files/${id}${isPreview ? '/preview' : ''}`, {
    baseURL,
    responseType: 'blob',
  });
};

export const getMessagesGPT = (user_id?: number) => {
  return axios.get('/api/gpt/messages', { params: { user_id } });
};

export const postMessageGPT = (data: FormData) => {
  return axios.post('/api/gpt/message', data);
};
